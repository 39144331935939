import React, { useEffect } from "react"
import { useOAuthTokens } from "src/hooks/useOAuthTokens"
import { AuthenticatedApiProvider } from "src/context/AuthenticatedApiContext"
import { homeRoute } from "src/routes/routes"
import { navigate } from "gatsby"
import { useAccount } from "../hooks/useAccount"

export default function LogoutPage() {
  const { accessToken, forgetOAuthTokens } = useOAuthTokens()
  const { forgetAccount } = useAccount()

  useEffect(() => {
    forgetOAuthTokens()
    forgetAccount()
    navigate(homeRoute)
  }, [accessToken])

  return <AuthenticatedApiProvider />
}
